
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment'
// const url = environment.serviceUrl



@Injectable({
  providedIn: 'root'
})
export class LoginService {
  url = environment.serviceUrl
  constructor(private http: HttpClient,) {
    if (location.hostname != "localhost")
      this.url = `${location.origin}/api/`;
  }


  login(data) {
    return this.http.post(this.url + 'Account/Login', data)
  }
  loginNew(data) {
    return this.http.post('https://oukadmissionsdev.konza.go.ke/api/' + 'Account/InitialLogin', data)
  }

  forgotPassword(payload: string) {
    return this.http.get(`${this.url}${payload}`);
  }
}

